import React, { useEffect, useRef } from "react"
import Img from "gatsby-image"
import { useStaticQuery, Link } from "gatsby"
import ReactHtmlParser from "react-html-parser"
import { Hidden } from "@material-ui/core"

import { Breadcrumb } from "../../ui/Breadcumb"
import { getImgFluid } from "../../utils/data"

export const HallBody = ({ data, images }) => {
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const allRoomData = useStaticQuery(graphql`
    query {
      allWordpressPost(
        filter: { categories: { elemMatch: { slug: { eq: "rooms" } } } }
      ) {
        edges {
          node {
            path
            wordpress_id
            acf {
              basic_info {
                name_zh
                name_en
              }
            }
          }
        }
      }
    }
  `)

  const roomData = data.room_links
    .map(roomLink =>
      allRoomData.allWordpressPost.edges.find(
        edge => roomLink.room[0].wordpress_id === edge.node.wordpress_id
      )
    )
    .filter(Boolean)

  useEffect(() => {
    const $ = window && window.jQuery

    if ($) {
      $(".owl-carousel").owlCarousel({
        center: true,
        items: 1,
        loop: true,
        autoplay: true,
        autoplayTimeout: 3000,
        margin: 0,
        stagePadding: 0,
        nav: false,
        dots: true,
        autoplayHoverPause: true,
        lazyLoad: true,
        lazyLoadEager: 6,
      })
    }
  }, [])

  useEffect(() => {
    const $ = window && window.jQuery

    if ($) {
      prevRef.current = $(".owl-prev")
      nextRef.current = $(".owl-next")
      if (prevRef.current || nextRef.current) {
        if (prevRef.current[0]) {
          prevRef.current[0].innerHTML =
            '<i class="fas fa-chevron-left nav-btn prev"></i>'
        }
        if (nextRef.current[0]) {
          nextRef.current[0].innerHTML =
            '<i class="fas fa-chevron-right nav-btn next"></i>'
        }
      }
    }
  }, [])

  return (
    <div className="hall-body">
      <Breadcrumb
        paths={data.basic_info.breadcumb.map(path => ({
          text: path.name,
          link_url: path.link_name,
        }))}
      />
      <div className="align-items-stretch wrapper main-content">
        <div className="row mb-3 mb-md-4">
          <Hidden smUp>
            <div className="col-24 block-infomation">
              <div
                className="h-100 w-100 bg"
                style={{
                  backgroundImage: `url(${
                    getImgFluid(data.content.background_image).src
                  })`,
                }}
              >
                <div className="content">
                  <Img
                    fluid={getImgFluid(data.content.logo)}
                    alt={data.content.logo.source_url}
                    imgStyle={{
                      width: 60,
                      height: 60,
                    }}
                    style={{
                      margin: "0 auto 8px auto",
                      width: 60,
                      height: 60,
                    }}
                  />
                  {ReactHtmlParser(data.content.text)}
                </div>
              </div>
            </div>
          </Hidden>
          <div className="col-24 col-md-12 mb-3 p-0 mb-md-0">
            <div className="owl-carousel">
              {images?.map((image, idx) => (
                <Img
                  fluid={getImgFluid(image)}
                  alt={image.alt_text}
                  fadeIn={false}
                  className="h-100 carousel-image"
                  key={idx}
                />
              ))}
            </div>
          </div>
          <div className="row col-24 ml-0 col-md-12 flex-column">
            <div className="row">
              <Hidden xsDown>
                <div className="col-24 block-infomation">
                  <div
                    className="h-100 w-100 bg"
                    style={{
                      backgroundImage: `url(${
                        getImgFluid(data.content.background_image).src
                      })`,
                    }}
                  >
                    <div className="content">
                      <Img
                        fluid={getImgFluid(data.content.logo)}
                        alt={data.content.logo.source_url}
                        imgStyle={{
                          width: 60,
                          height: 60,
                        }}
                        style={{
                          margin: "0 auto 8px auto",
                          width: 60,
                          height: 60,
                        }}
                      />
                      {ReactHtmlParser(data.content.text)}
                    </div>
                  </div>
                </div>
              </Hidden>
            </div>
            <div className="row">
              <div className="col-12 block-room-display-2">
                <Img
                  fluid={getImgFluid(data.small_1_image)}
                  alt={data.small_1_image?.alt_text}
                />
              </div>
              <div className="col-12 block-room-display-2">
                <Img
                  fluid={getImgFluid(data.small_2_image)}
                  alt={data.small_2_image?.alt_text}
                />
              </div>
            </div>
            <div className="row flex-grow-1 position-relative overflow-hidden">
              <div className="col-24 block-room-display-1">
                <Img
                  fluid={getImgFluid(data.large_image)}
                  alt={data.large_image?.alt_text}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="divider my-3 pr-md-3 my-md-4"></div>

        <div className="room-blocks m-0 mt-3 mt-md-4 py-0 pr-md-3">
          <div className="row">
            {roomData.map(({ node: { path, acf: room } }, idx) => {
              const targetRoomsLink = data.room_links[idx]

              return (
                <div className="col-12 col-sm-8 col-md-6 my-3" key={idx}>
                  <Link to={path}>
                    <div
                      className="room-block"
                      style={{
                        backgroundSize: "cover",
                        backgroundPosition: "50% 50%",
                        backgroundImage: `url(${
                          getImgFluid(targetRoomsLink.image).src
                        })`,
                      }}
                    >
                      <div className="content">
                        {/* <p className="title">{room.basic_info.name_zh}</p>
                        <p className="subtitle">{room.basic_info.name_en}</p> */}
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
